import React, {useEffect, useState} from 'react';
import {Typography, Paper, Box, Divider} from '@mui/material';
import { getAvailability } from '../utils/apiWrapper';
import { Container } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';

const Availability = () => {
    const [availability, setAvailability] = useState([]);
    const [uniqueAvailableSites, setUniqueAvailableSites] = useState([]);

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    useEffect(() => {
        async function fetchData() {
            const availableCampsites = await getAvailability();
            setAvailability(availableCampsites);
            let uniques = [
                ...new Map(availableCampsites.scanResults.map((item) => [item["name"], item])).values(),
            ];
            setUniqueAvailableSites(uniques);
        }

        fetchData();

    }, []);

    return (
        <Container maxWidth={'sm'} sx={{paddingTop: '20px'}}>
            <Typography variant='h4' >Current Availability</Typography>
            <Typography paddingBottom='20px'>Last Scan: {new Date(availability.scanTime).toLocaleString()}</Typography>
                    {uniqueAvailableSites.length === 0 ? <Typography>No Sites Available</Typography> :
                    uniqueAvailableSites.map((oneAvailability, i) => {
                        return (
                            <Box mb={2} key={i}>
                                <Paper elevation={5} key={i}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: '15px 20px 10px 20px'
                                    }}>
                                        <Typography variant='h6'>{oneAvailability.name}&nbsp;</Typography>
                                        <Link rel={"noopener noreferrer"} target={'_blank'} href={`https://www.recreation.gov/camping/campgrounds/${oneAvailability.recgov_id}`}>
                                            <OpenInNewIcon fontSize={'14px'} />
                                        </Link>
                                    </div>
                                    <Divider/>
                                    <Box padding={'10px 20px 15px 30px'}>
                                    {availability.scanResults.map((oneDate, i) => {
                                        if (oneDate.name === oneAvailability.name) {
                                            return (<Typography key={i}>&nbsp;&nbsp;&nbsp;{oneDate.date_available.slice(0, -14)} - {weekday[new Date(oneDate.date_available.slice(0, -1)).getDay()]}</Typography>);
                                        }
                                    })}
                                    </Box>
                                </Paper>
                            </Box>);
                    })}
                
        </Container>
    );
}

export default Availability;