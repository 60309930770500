import React from "react";
import Userfront from "@userfront/react";
import Box from '@mui/material/Box';

Userfront.init("5nx8d7mn");

export function GetUserEmail() {
    return Userfront.user.email;
}

function addStyling(page) {
    return (<Box sx={{margin: 5}}>{page}</Box>);
}

const BuiltSignupForm = Userfront.build({
    toolId: "ladard"
});

export function SignupForm() {
    return addStyling(<BuiltSignupForm />);
}

const BuiltLoginForm = Userfront.build({
    toolId: "rrmrdl"
});

export function LoginForm() {
    return addStyling(<BuiltLoginForm />);
}

const BuiltPasswordResetForm = Userfront.build({
    toolId: "nnanmd"
});

export function PasswordResetForm() {
    return addStyling(<BuiltPasswordResetForm />);
}

const BuiltLogoutButton = Userfront.build({
    toolId: "kalabd"
});

export function LogoutButton() {
    return <BuiltLogoutButton />;
}