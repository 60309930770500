import Userfront from '@userfront/react';

export const navTabs = [ {name: 'Availability', page: 'availability', requiresLogin: true, requiresAdmin: false},
                         {name: 'Notifications', page: 'notifications', requiresLogin: true, requiresAdmin: false}];
export const userfrontKey = "5nx8d7mn";

export function getAllViewableTabs() {
    var pageList = [];

    navTabs.forEach((oneItem) => {
        if ( oneItem.requiresAdmin ) {
            if (Userfront.user.hasRole("admin")) {
                pageList.push(oneItem);
            }
        } 
        else if ( oneItem.requiresLogin ) {
            if (Userfront.tokens.accessToken) {
                pageList.push(oneItem);
            }
        }
    });

    return pageList;
}
