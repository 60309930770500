import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch, Typography, Grid, Paper, Link, Button, Divider} from '@mui/material';
import { getNotificationConfig, updateNotification } from '../utils/apiWrapper';
import { Container } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GetUserEmail } from '../utils/UserfrontHelpers';

const Notifications = () => {
    const [notificationConfig, setNotificationConfig] = useState([]);
    
    useEffect(() => {
        async function fetchData() {
            const newSavedSearches = await getNotificationConfig();
            setNotificationConfig(newSavedSearches);
        }

        fetchData();
    }, []);

    const handleChange = async (event) => {
        // Flip the switch
        const newState = notificationConfig.map((oneCampground) => {
            if (oneCampground.recgov_id.toString() === event.target.id) {
                oneCampground.send_notifications = !oneCampground.send_notifications;
                return oneCampground;
            }
            return oneCampground;
        });
        setNotificationConfig(newState);

        // Set the search setting the BE
        await updateNotification(event.target.id, event.target.checked);
    };

    const SetAllSelection = async (selectAll) => {
        const newState = await Promise.all(notificationConfig.map(async (oneCampground) => {
            if (oneCampground.send_notifications !== selectAll) {
                oneCampground.send_notifications = selectAll;
                await updateNotification(oneCampground.recgov_id, oneCampground.send_notifications);
            }
            return oneCampground;
        }));
        setNotificationConfig(newState);    
    }

    return (
        <Container sx={{paddingTop: '20px', paddingBottom: '40px'}}>
            <Typography variant='h4' align='left'>Enable Notifications</Typography>
            <Paper elevation={5}>
                <Grid container columns={{xs:6, sm:12}} alignItems={'center'} sx={{padding: '20px 20px 15px 20px'}}>
                    <Grid item xs={6} textAlign={{xs: 'center', md: 'left'}}>
                        <Typography>Notifications will get sent to: {GetUserEmail()}</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign={{xs: 'center', md: 'right'}}>
                        <Button variant='text' onClick={async () => await SetAllSelection(false)}>Unselect All</Button>&nbsp;
                        <Button variant='text' onClick={async () => await SetAllSelection(true)}>Select All</Button>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container sx={{padding: '20px 0px 20px 20px'}} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {notificationConfig.map((oneCampground, i) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} key={i}>
                                <FormControlLabel key={i}
                                sx={{marginRight: '5px'}}
                                control={<Switch 
                                    id={oneCampground.recgov_id.toString()}
                                    inputProps={{ 'aria-label': 'controlled' }} 
                                    onChange={handleChange}
                                    checked={oneCampground.send_notifications ? true : false}/>} 
                            label={oneCampground.name} />
                            <Link rel={"noopener noreferrer"} target={'_blank'} href={`https://www.recreation.gov/camping/campgrounds/${oneCampground.recgov_id}`}>
                                <OpenInNewIcon fontSize={'14px'} />
                            </Link>
                            </Grid>);
                    })}
                </Grid>
            </Paper>
        </Container>
    );
}

export default Notifications;