import Userfront from "@userfront/react";

var rootAPIPath = process.env.NODE_ENV === 'production' ? "https://api.kingnerd.com" : "http://localhost:3001";

export async function getAllCampgrounds() {
    try {
        let response = await fetch(`${rootAPIPath}/getAllCampgrounds`,);
        let json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
    }
}

export async function getSavedSearches() {
    try {
        let response = await fetch(`${rootAPIPath}/getsavedsearches`,
        {method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
        }});
        let json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
    }
}

export async function getNotificationConfig() {
    try {
        let response = await fetch(`${rootAPIPath}/getnotificationconfig`,
        {method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
        }});
        let json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
    }
}

export async function getAvailability() {
    try {
        var res = await fetch(`${rootAPIPath}/getavailability`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
            }});
        const json = await res.json();
        return json;
    } catch (error) {
        console.log(error);
    }
}

export async function updateNotification(recgov_id, check_state) {
    try {
        await fetch(`${rootAPIPath}/updatenotification`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
            },
            body: JSON.stringify({ recgovID: recgov_id, checkState: check_state })
        });
        return;
    } catch (error) {
        console.log(error);
    }
}

export async function updateSearch(recgov_id, check_state) {
    try {
        await fetch(`${rootAPIPath}/updatesearch`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
            },
            body: JSON.stringify({ recgovID: recgov_id, checkState: check_state })
        });
        return;
    } catch (error) {
        console.log(error);
    }
}

export async function startScan() {
    try {
        let response = await fetch(`${rootAPIPath}/startscan`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Userfront.tokens.accessToken}`,
            }
        });
        let json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
    }
}