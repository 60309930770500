import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch, Typography, Grid, Paper, Button, Stack, LinearProgress} from '@mui/material';
import { getSavedSearches, updateSearch } from '../utils/apiWrapper';
import { Container } from '@mui/system';
import { startScan } from '../utils/apiWrapper';
import { styled } from '@mui/material/styles';

const Campsites = () => {
    const [savedSearches, setSavedSearches] = useState([]);
    const [scanResponse, setScanResponse] = useState([]);
    const [showSpinners, setShowSpinners] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const newSavedSearches = await getSavedSearches();
            setSavedSearches(newSavedSearches);
        }

        fetchData();
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F5F5F5',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    const handleChange = async (event) => {
        // Flip the switch
        const newState = savedSearches.map((oneCampground) => {
            if (oneCampground.recgov_id.toString() === event.target.id) {
                oneCampground.scan_enabled = !oneCampground.scan_enabled;
                return oneCampground;
            }
            return oneCampground;
        });
        setSavedSearches(newState);

        // Set the search setting the BE
        await updateSearch(event.target.id, event.target.checked);
    };

    async function startCGScan() {
        setShowSpinners(true);
        setScanResponse([]);
        const scanResults = await startScan();
        setScanResponse(scanResults);
        setShowSpinners(false);
    }

    return (
        <Container sx={{paddingTop: '20px'}}>
            <Typography variant='h4' align='left' paddingBottom='20px'>All Lookouts</Typography>
            <Paper>
                <Grid container sx={{padding: '0px 0px 20px 20px'}} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {savedSearches.map((oneCampground, i) => {
                        return (
                            <Grid item xs={3} sm={3} md={3} key={i}>
                                <FormControlLabel key={i}
                                control={<Switch 
                                    id={oneCampground.recgov_id.toString()}
                                    inputProps={{ 'aria-label': 'controlled' }} 
                                    onChange={handleChange}
                                    checked={oneCampground.scan_enabled}/>} 
                            label={oneCampground.name} />
                            </Grid>);
                    })}
                </Grid>
            </Paper>
            <Typography variant='h4' align='left' paddingTop='20px'>Start Scan</Typography>
            <Paper sx={{marginBottom: '20px', minHeight: '250px'}} >
                <Button variant="contained" sx={{margin: 5}} onClick={startCGScan}>Start Scan</Button>
                <Container sx={{width: '600px'}}>
                {scanResponse.message ? 
                    <Stack spacing={2} sx={{paddingBottom: '20px'}}>
                        {scanResponse.message.sort().map((oneItem, i) => {
                        return (<Item key={i} >{oneItem}</Item>);})}
                    </Stack> 
                    :
                    showSpinners && <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <Typography variant='h6'>Scanning....</Typography>
                        <LinearProgress color="secondary" />
                        <LinearProgress color="success" />
                        <LinearProgress color="inherit" />
                    </Stack>
                }
            </Container>
            </Paper>
        </Container>
    );
}

export default Campsites;