import React from 'react';
import {Box, Typography} from '@mui/material';

const Home = () => {
    
    return (
        <>   
            <Box sx={{boxShadow: {md: 3}, display: 'flex', flexDirection: { xs: "column", md: "row"}, justifyContent: 'center', height: { xs: '600px', md:'450px'}}}>
                <Box sx={{flex: '0, 0, 50%', order: { xs: 2, md: 1}, width: { xs: '100%', md:'40%'}}}>
                    <Box sx={{padding: '40px'}}>
                        <Typography variant='h3'>Camp Scanner</Typography>
                        <Typography variant='h5'>Getting you the sites that you deserve</Typography>
                        <Typography variant='h7'>In a way that requires minimal effort</Typography>
                    </Box>
                </Box>
                <Box sx={{flex: '0, 0, 50%', order: { xs: 1, md: 2}, width: { xs: '100%', md:'60%'}, height: '100%', overflow: 'hidden', clipPath: { md: 'polygon(15% 0%, 100% 0px, 100% 100%, 0% 100%)'}}}>
                    <Box sx={{width: '100%', height: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url('/images/firelookout.jpg')`}}></Box>
                </Box>
            </Box>
        </> 
    );
}

export default Home;