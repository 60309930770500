import React from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation} from "react-router-dom";
import NavBar from './common/NavBar.tsx';
import Userfront from "@userfront/react";
import { LoginForm, PasswordResetForm, SignupForm } from './utils/UserfrontHelpers';
import { Container } from "@mui/material";
import Home from "./pages/Home";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import Campsites from './pages/Campsites';
import Availability from "./pages/Availability";
import Notifications from "./pages/Notifications";

Userfront.init("5nx8d7mn");

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white"
        },
        root: {
          color: 'black'
        }     
      }
    }
  }
});


function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false} sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
          <NavBar/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/reset" element={<PasswordResetForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/campsites" element={<RequireAuth><Campsites /></RequireAuth>} />
            <Route path="/availability" element={<RequireAuth><Availability /></RequireAuth>} />
            <Route path="/notifications" element={<RequireAuth><Notifications /></RequireAuth>} />
            <Route path="*"element={<div><h2>404 Page not found</h2></div>}/>
          </Routes>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function RequireAuth({children}) {
  let location = useLocation();
  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
