import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CabinIcon from '@mui/icons-material/Cabin';
import { Link } from 'react-router-dom';
import Userfront from '@userfront/react';
import { LogoutButton } from '../utils/UserfrontHelpers';
import { getAllViewableTabs, userfrontKey, navTabs } from '../utils/AppUtils';
import { useLocation } from 'react-router-dom';

Userfront.init(userfrontKey);

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [onLoginPage, setOnLoginPage] = useState(true);
  
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let location = useLocation();

  useEffect(() => {
    setOnLoginPage(location.pathname.endsWith('login'));
  }, [location]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton component={Link} to="/" sx={{display: { xs: 'none', md: 'flex'}}}>
            <CabinIcon/>
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Camp Scanner
          </Typography>

          {Userfront.tokens.accessToken && <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="hamburger menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navTabs.map((page) => (
                <MenuItem 
                  component={Link}
                  to={page.page}
                  key={page.name}
                  onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>}
          <IconButton component={Link} to="/" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, flexGrow: 1 }}>
            <CabinIcon/>
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {getAllViewableTabs().map((page) => (
              <Button
                key={page.page}
                component={Link}
                color={'inherit'}
                to={page.page}
                sx={{ my: 2, display: 'block', paddingTop: '9px'}}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {onLoginPage ? '' : Userfront.tokens.accessToken ?  <LogoutButton /> : <Button 
            key="loginbutton"
            component={Link}
            to={'/login'}
            color="inherit"
            sx={{ display: 'block' }}
          >
            Login
          </Button>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
